import React from "react";

function ForBuisness() {
  return (
    <>
      <div>For Buisness</div>
    </>
  );
}

export default ForBuisness;
