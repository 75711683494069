// src/components/Navbar.js
import React from "react";
import { Home, Megaphone, BriefcaseBusiness } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const currentPage = location.pathname.substring(1) || "/";

  console.log(currentPage);
  const navigate = useNavigate();
  const navItems = [
    {
      name: "Home",
      icon: Home,
      link: "/",
    },
    {
      name: "Become Promoter",
      icon: Megaphone,
      link: "become-promoter",
    },
    {
      name: "For Business",
      icon: BriefcaseBusiness,
      link: "for-buisness",
    },
  ];

  return (
    <nav className="bg-[#FCFCFC] shadow-md">
      <div className="container mx-auto flex items-center justify-between px-4 py-4">
        {/* Logo */}
        <div className="flex items-center space-x-2">
          <img
            src="me-ask-logo.svg"
            alt="Logo"
            className="h-[32px] w-[144px]"
          />
        </div>

        {/* Navigation Links */}
        <ul className="hidden space-x-8 md:flex">
          {navItems.map((item) => (
            <li
              key={item.link}
              className={`flex cursor-pointer items-center space-x-2 px-4 py-2 ${
                currentPage === item.link
                  ? "text-[#29B3D2] underline underline-offset-4"
                  : "text-gray-700 hover:text-gray-900"
              }`}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <item.icon
                className={`h-5 w-5 ${
                  currentPage === item.link ? "text-[#29B3D2]" : "text-gray-700"
                }`}
              />
              <span>{item.name}</span>
            </li>
          ))}
        </ul>
        <button className="text-gray-600 focus:outline-none md:hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
