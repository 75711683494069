import React from "react";

function BecomePromoterPage() {
  return (
    <>
      <div>Become Promoter</div>
    </>
  );
}

export default BecomePromoterPage;
