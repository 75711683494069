import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Navbar from "./components/navbar/navbar";
import BecomePromoterPage from "./pages/BecomePromoter";
import ForBuisness from "./pages/ForBuisness";
import HomePage from "./pages/Home";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/become-promoter"
            element={<BecomePromoterPage />}
          />
          <Route exact path="/for-buisness" element={<ForBuisness />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
