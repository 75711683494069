import React from "react";
import Marquee from "../components/marquee/marquee";

function HomePage() {
  return (
    <>
      <Marquee />
      <div>Homepage</div>
    </>
  );
}

export default HomePage;
