import React from "react";

const Marquee = () => {
  return (
    <div className="h-12 w-full overflow-hidden bg-yellow-300">
      {/* Scrolling content */}
      <div className="animate-scrollSmooth flex w-full font-bold text-black">
        <span className="mx-8 w-1/4 flex-shrink-0 text-lg font-bold text-black">
          Coming soon!!!
        </span>
        <span className="mx-8 w-1/4 flex-shrink-0 text-lg font-bold text-black">
          Coming soon!!!
        </span>
        <span className="mx-8 w-1/4 flex-shrink-0 text-lg font-bold text-black">
          Coming soon!!!
        </span>
        <span className="mx-8 w-1/4 flex-shrink-0 text-lg font-bold text-black">
          Coming soon!!!
        </span>
      </div>
    </div>
  );
};

export default Marquee;
